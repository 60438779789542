* {
  box-sizing: border-box;
}

:root {
  --primary: #778A35;
  --secondary: #D1E2C4;
  --white: #fff;
  --transition-2: .2s;
  --transition-3: .3s;
}


body {
  height: 100vh;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat", "IBM Plex Sans Arabic", sans-serif;
  background-color: #ECF2F7;
}


.todo-app {
  max-width: 750px;
  padding: 20px;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 30px;
  border: 1px solid rgba(0, 0, 0, 0.093);
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  background-color: #D4D9DD;
}

@media (max-width: 750px) {
  .todo-app {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 10px;
  }
}

/* ÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷--Form Style--÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷ */
.form__group {
  position: relative;
  padding: 15px 0 0;
  margin-top: 10px;
  width: 50%;
}

.form__field {
  border: 0;
  border-bottom: 1px solid #ffffff8a;
  outline: 0;
  font-size: 1.3rem;
  color: #404040;
  padding: 7px 0;
  background: transparent;
  transition: border-color var(--transition-2);
  margin-bottom: 50px;
}

.form__field::placeholder {
  color: transparent;
}

.form__field:placeholder-shown~.form__label {
  font-size: 1.3rem;
  cursor: text;
  top: 20px;
}

.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.5s;
  font-size: 1rem;
  color: #404040;
}

.form__field:focus~.form__label,
.form__field:valid~.form__label {
  top: 0;
  display: block;
  transition: var(--transition-3);
  font-size: 1rem;
  color: var(--primary);
  font-weight: 400;
}

.form__field:focus {
  padding-bottom: 6px;
  font-weight: 500;
  border-width: 3px;
  border-image: linear-gradient(to right, var(--primary), var(--secondary));
  border-image-slice: 1;

}

.input {
  display: grid;
  place-items: center;
}

.form__field:required,
.form__field:invalid {
  box-shadow: none;
}


.input__box:focus {
  box-shadow: 1px 1px 0px 1000px #000000a1;
}


/* ÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷--Submit Button--÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷ */
.input__submit {
  all: unset;
  font-size: 16px;
  background: transparent;
  border: none;
  position: relative;
  color: #f0f0f0;
  cursor: pointer;
  z-index: 1;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.input__submit::after,
.input__submit::before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -99999;
  transition: all .4s;
}

.input__submit::before {
  transform: translate(0%, 0%);
  width: 100%;
  height: 100%;
  background: var(--primary);
  border-radius: 10px;
}

.input__submit::after {
  transform: translate(10px, 10px);
  width: 35px;
  height: 35px;
  background: #ffffff15;
  border-radius: 50px;
}

.input__submit:hover::before {
  transform: translate(5%, 20%);
  width: 110%;
  height: 110%;
}

.input__submit:hover::after {
  border-radius: 10px;
  transform: translate(0, 0);
  width: 100%;
  height: 100%;
}

.input__submit:active::after {
  transition: 0s;
  transform: translate(0, 5%);
}


/* ÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷--Missions Container--÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷ */
.todo-container {
  max-height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 10px;
  scroll-behavior: smooth;
}

.todo-container::-webkit-scrollbar {
  width: 8px;
}

.todo-container::-webkit-scrollbar-track {
  background: #ffffff15;
  border-radius: 50px;
}

.todo-container::-webkit-scrollbar-thumb {
  background: #ffffff1d;
  border-radius: 50px;
}

.todo-container::-webkit-scrollbar-thumb:hover {
  background: var(--primary);
}

.todo-item {
  padding: 10px;
  border-radius: 5px;
  font-weight: 100;
  position: relative;
  overflow: hidden;
  transition: var(--transition-3);
  width: 360px;
  margin-bottom: 20px;
}


.todo-item:hover {
  scale: 1.02;
}

.todo-item::before,
.todo-item::after {
  content: "";
  background-color: #ffffff36;
  position: absolute;
  border-radius: 50%;
}

.todo-item::before {
  width: 200px;
  height: 200px;
  right: -80px;
  top: -100px;
}

.todo-item::after {
  width: 40px;
  height: 40px;
  right: 115px;
  top: 35px;
}

.todo-item-done {
  scale: 0.98;
}

.todo-item-done:hover {
  box-shadow: 0 0 5px 1px whitesmoke;
}

.todo-span-style {
  position: absolute;
  left: 50%;
  top: 30%;
  transform: translate(-50%, -50%);
  font-size: 50px;
  color: rgba(0, 0, 0, 0.073);
  letter-spacing: 30px;

}

.todo-item__mission {
  width: 90%;
  font-weight: 600;
  color: var(--white);
  font-size: 24px;
  word-break: break-all;
  transition: var(--transition-2);
  cursor: pointer;
  position: relative;
}


.todo-item__mission::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 0%;
  height: 1.5px;
  background-color: gray;
  transition: var(--transition-2);
  opacity: 1;
}

.todo-item__mission-done {
  opacity: .5;
}

.todo-item__mission-done::before {
  width: 100%;
}

.todo-item__timestamp {
  max-width: 90%;
  color: var(--white);
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: normal;
  font-size: 11px;
  margin: 5px;
}


.todo-item__buttons {
  position: absolute;
  right: 0%;
  top: 0%;
  height: 100%;
  opacity: 0;
  transition: var(--transition-3);
}

.todo-item:hover .todo-item__buttons {
  opacity: 1;
}

.todo-item__button-delete,
.todo-item__button-edit {
  background-color: transparent;
  border: none;
  color: var(--white);
  background: #ffffff15;
  display: block;
  height: 50%;
  cursor: pointer;
  transition: var(--transition-2);
}


.todo-item__button-delete:hover,
.todo-item__button-edit:hover {
  color: #11998e;

}

.todo-item__timestamp {
  font-size: 11px;
  font-weight: 300;
}

@media (max-width:750px) {
  .todo-item {
    width: 310px;
  }

  .todo-item__mission {
    font-size: 19px;
  }
}